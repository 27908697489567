import Link from "next/link"
import PropTypes from "prop-types"
import { linkPropTypes } from "/utils/types"
import React, {useContext} from "react"
import { MouseContext } from "../../context/mouseContext";
import { TransitionElementContext } from "../../context/transitionElementContext";
import { useRouter } from "next/router";
import { scrolling } from "../../utils/hooks";

const CustomLink = ({ link, children, hovertext, extraFunction = null, hasHoverInteraction = false }) => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  const { pageTransitionElementHandler, isFromHeaderMenu } = useContext(TransitionElementContext);
  const isInternalLink = link?.url?.startsWith("/")
  const router = useRouter();

    React.useEffect(()=>{
      if (isInternalLink) {
        router.prefetch(link.url, link.url)
      }
    },[])
  const onMouseEnter = e => {
    // console.log(children)
    // if(!noMouseEvents){
    if(hasHoverInteraction){
      cursorChangeHandler("hovered", hovertext);
    }
    // }
  }

  const onMouseLeave = e => {
    // if(!noMouseEvents){
    // if(hasHoverInteraction){
      cursorChangeHandler("", "");
    // }
    // }
  }

  const onClickHandler = (e, url, word="", wordFunny="") => {
    e.preventDefault();
    // console.log("testing click")
    // pageTransitionElementHandler(url, word, wordFunny);
    cursorChangeHandler("","")
    // if(extraFunction){
    //   setTimeout(()=>{
    //     extraFunction();
    //   }, 700)
    // }
    // isFromHeaderMenu(fromHeaderMenu);
    // isFromHeaderMenu(false);
    // console.log(link.url, router.asPath)
    if(link.url !== router.asPath){

      scrolling.disable();
      pageTransitionElementHandler(url, word, wordFunny);
      cursorChangeHandler("","")
      if(extraFunction){
        setTimeout(()=>{
          extraFunction();
          // router.push(link.url, link.url, { scroll: false })
        }, 700)
      }
      setTimeout(()=>{
        router.push(link.url, link.url, { scroll: false })
      }, 700)
      // router.push(link.url, link.url, { scroll: false })
    }else{
      window.scrollTo({
        top:0,
        behavior: 'smooth',
      });
    }
  }

  // For internal links, use the Next.js Link component
  if (isInternalLink) {
    return (
      <a href={link.url} as={link.url} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={(e)=>{onClickHandler(e, link.url, "transitionWord","transitionWordFunny")}}>
        {/* <a>{children}</a> */}
        {children}
      </a>
    )
  }

  // const onClickHandler = (url, word="", wordFunny="") => {
  //   console.log("testing click")
  //   pageTransitionElementHandler(url, word, wordFunny);
  //   cursorChangeHandler("","")
  //   // isFromHeaderMenu(fromHeaderMenu);
  //   // isFromHeaderMenu(false);
  // }

  // // For internal links, use the Next.js Link component
  // if (isInternalLink) {
  //   return (
  //     <Link href="/[[...slug]]" passHref as={link.url} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={()=>{onClickHandler(link.url, "transitionWord","transitionWordFunny")}}>
  //       <a onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={()=>{onClickHandler(link.url, "transitionWord","transitionWordFunny")}}>{children}</a>
  //     </Link>
  //   )
  // }

  // Plain <a> tags for external links
  if (link?.newTab) {
    return (
      <a href={link.url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  }

  return (
    <a href={link?.url} target="_self">
      {children}
    </a>
  )
}

CustomLink.propTypes = {
  link: linkPropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default CustomLink
