import React from 'react'

const Link = () => {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.5548 3.06839H0.997886V0.925415H19.2131V19.1407H17.0702V4.5837L1.75554 19.8983L0.240234 18.3831L15.5548 3.06839Z" fill="currentColor"/>
    </svg>
  )
}

export default Link

