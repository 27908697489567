const qs = require('qs');

export function getStrapiURL(path) {
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://0.0.0.0:1338/api'
  }${path}`
}

export function getStrapiURLRaw() {
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL_RAW || 'http://0.0.0.0:1338'
  }`
}

// Helper to make GET requests to Strapi
export async function fetchAPI(path, options = {}) {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  }
  const requestUrl = getStrapiURL(path)
  // console.log("\n\n\n\n", requestUrl, "\n\n\n\n")
  const response = await fetch(requestUrl, mergedOptions)

  if (!response.ok) {
    // console.error(response.statusText)
    throw new Error(`An error occured please try again`)
  }
  const data = await response.json()
  return data
}

/**
 *
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
export async function getPageData(params, locale, preview) {
  
  const slug = params.slug.join('/')

  const query = qs.stringify({
    filters: {
      slug: {$eq: slug}
    },
  }, {
    encodeValuesOnly: true,
  });

  // console.log(`/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //   preview ? '&publicationState=preview' : ''
  // }`)

  // Find the pages that match this slug
  // const pagesData = await fetchAPI(
  //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //     preview ? '&publicationState=preview' : ''
  //   }`
  // )
  const pagesData = await fetchAPI(
    `/pages?${query}&locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (pagesData.data == null || pagesData.data.length === 0) {
    return null
  }
  // console.log("after check", pagesData.data, pagesData.data[0]);
  // Return the first item since there should only be one result per slug
  return pagesData.data[0]
}

/** HOMEPAGE
 *
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
export async function getHomepageData(params, locale, preview) {
  const pagesData = await fetchAPI(
    `/homepage?locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (pagesData.data == null || pagesData.data.length === 0) {
    return null
  }
  // Return the first item since there should only be one result per slug
  return pagesData.data
}

/** ABOUT US
 *
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
export async function getAboutData(params, locale, preview) {
  const pagesData = await fetchAPI(
    `/about?locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (pagesData.data == null || pagesData.data.length === 0) {
    return null
  }
  // Return the first item since there should only be one result per slug
  return pagesData.data
}


/** Projects page
 *
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
export async function getProjectsPageData(params, locale, preview) {
  const pagesData = await fetchAPI(
    `/projects-page?locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (pagesData.data == null || pagesData.data.length === 0) {
    return null
  }
  // Return the first item since there should only be one result per slug
  return pagesData.data
}

/** Events page
 *
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
export async function getEventsPageData(params, locale, preview) {
  const pagesData = await fetchAPI(
    `/events-page?locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (pagesData.data == null || pagesData.data.length === 0) {
    return null
  }
  // Return the first item since there should only be one result per slug
  return pagesData.data
}

/** News page
 *
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
export async function getNewsPageData(params, locale, preview) {
  const pagesData = await fetchAPI(
    `/news-page?locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (pagesData.data == null || pagesData.data.length === 0) {
    return null
  }
  // Return the first item since there should only be one result per slug
  return pagesData.data
}


/**
 *
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
 export async function getFieldPageData(params, locale, preview) {
  
  const slug = params.slug.join('/')

  const query = qs.stringify({
    filters: {
      slug: {$eq: slug}
    },
  }, {
    encodeValuesOnly: true,
  });

  // console.log(`/fields?slug=${slug}&locale=${locale}&publicationState=live${
  //   preview ? '&publicationState=preview' : ''
  // }`)

  // Find the pages that match this slug
  // const pagesData = await fetchAPI(
  //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //     preview ? '&publicationState=preview' : ''
  //   }`
  // )
  const pagesData = await fetchAPI(
    `/fields?${query}&locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (pagesData.data == null || pagesData.data.length === 0) {
    return null
  }
  // console.log("after check", pagesData.data, pagesData.data[0]);
  // Return the first item since there should only be one result per slug
  return pagesData.data[0]
}

// Get site data from Strapi (metadata, navbar, footer...)
export async function getGlobalData(locale) {
  // console.log(locale)
  const query = `/global?locale=${locale}`;
  // console.log(query)
  const global = await fetchAPI(query)
  return global
}



/**
 *  PROJECTS
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
 export async function getProjectsPaginated(sort, locale, preview, field, page, pageSize) {
  
  // const slug = params.slug.join('/')

  let query = qs.stringify({
    // filters: {
    //   fields:{
    //     tag:{
    //       $eq: field
    //     }
    //   }
    // },
    sort: [ sort ? sort : "id:desc"],
    pagination:{
      page: page,
      pageSize: pageSize,
    }
  }, {
    encodeValuesOnly: true,
  });

  if(field !== "all"){
    query = qs.stringify({
      filters: {
        fields:{
          tag:{
            $eq: field
          }
        }
      },
    sort: [ sort ? sort : "id:desc"],
      pagination:{
        page: page,
        pageSize: pageSize,
      }
    }, {
      encodeValuesOnly: true,
    });
  }

  // Find the pages that match this slug
  // const pagesData = await fetchAPI(
  //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //     preview ? '&publicationState=preview' : ''
  //   }`
  // )
  const projectsData = await fetchAPI(
    `/projects?${query}&locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (projectsData.data == null || projectsData.data.length === 0) {
    return null
  }
  // console.log("after check", pagesData.data, pagesData.data[0]);
  // Return the first item since there should only be one result per slug
  return projectsData.data
}


 /**
 *  PROJECTS RELATED
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
  export async function getProjectsRelated(params, locale, preview, date, page, pageSize, currentSlug, time) {
  
    // const slug = params.slug.join('/')
    // console.log("ASDASDASDASDASDASDASDASDASDASDASDASDASD", currentSlug)
    let query = qs.stringify({
      filters: {
        // date:{
        //   $gt: date
        // },
        // time:{
        //   $gte: time
        // },
        slug:{
          $ne: currentSlug
        }
      },
      sort: ["id:desc"],
      pagination:{
        page: page,
        pageSize: pageSize,
      }
    }, {
      encodeValuesOnly: true,
    });
  
    // if(field !== "all"){
    //   query = qs.stringify({
    //     filters: {
    //       fields:{
    //         tag:{
    //           $eq: field
    //         }
    //       }
    //     },
    //     sort: ["createdAt:desc"],
    //     pagination:{
    //       page: page,
    //       pageSize: pageSize,
    //     }
    //   }, {
    //     encodeValuesOnly: true,
    //   });
    // }
  
    // Find the pages that match this slug
    // const pagesData = await fetchAPI(
    //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
    //     preview ? '&publicationState=preview' : ''
    //   }`
    // )
    const projectsData = await fetchAPI(
      `/projects?${query}&locale=${locale}&publicationState=live${
        preview ? '&publicationState=preview' : ''
      }`
    )
    // Make sure we found something, otherwise return null
    if (projectsData.data == null || projectsData.data.length === 0) {
      return null
    }
    // console.log("after check", pagesData.data, pagesData.data[0]);
    // Return the first item since there should only be one result per slug
    return projectsData.data
  }

  

/**
 *  EVENTS
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
 export async function getEventsPaginated(sort, locale, preview, field, page, pageSize) {
  
  // const slug = params.slug.join('/')
  // let sortField 
  let query = qs.stringify({
    // filters: {
    //   fields:{
    //     tag:{
    //       $eq: field
    //     }
    //   }
    // },
    sort: [ sort ? sort : "id:desc"],
    pagination:{
      page: page,
      pageSize: pageSize,
    }
  }, {
    encodeValuesOnly: true,
  });

  if(field !== "all"){
    query = qs.stringify({
      filters: {
        fields:{
          tag:{
            $eq: field
          }
        }
      },
      sort: [ sort ? sort : "id:desc"],
      pagination:{
        page: page,
        pageSize: pageSize,
      }
    }, {
      encodeValuesOnly: true,
    });
  }

  // Find the pages that match this slug
  // const pagesData = await fetchAPI(
  //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //     preview ? '&publicationState=preview' : ''
  //   }`
  // )
  const eventsData = await fetchAPI(
    `/events?${query}&locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (eventsData.data == null || eventsData.data.length === 0) {
    return null
  }
  // console.log("after check", pagesData.data, pagesData.data[0]);
  // Return the first item since there should only be one result per slug
  return eventsData.data
}

  /**
 *  EVENTS
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
 export async function getEventsRelated(params, locale, preview, date, page, pageSize, currentSlug, time) {
  
  // const slug = params.slug.join('/')

  let query = qs.stringify({
    filters: {
      date:{
        $gt: date
      },
      // time:{
      //   $gte: time
      // },
      slug:{
        $ne: currentSlug
      }
    },
    sort: ["date:asc", "time:asc"],
    pagination:{
      page: page,
      pageSize: pageSize,
    }
  }, {
    encodeValuesOnly: true,
  });

  // if(field !== "all"){
  //   query = qs.stringify({
  //     filters: {
  //       fields:{
  //         tag:{
  //           $eq: field
  //         }
  //       }
  //     },
  //     sort: ["createdAt:desc"],
  //     pagination:{
  //       page: page,
  //       pageSize: pageSize,
  //     }
  //   }, {
  //     encodeValuesOnly: true,
  //   });
  // }

  // Find the pages that match this slug
  // const pagesData = await fetchAPI(
  //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //     preview ? '&publicationState=preview' : ''
  //   }`
  // )
  const eventsData = await fetchAPI(
    `/events?${query}&locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (eventsData.data == null || eventsData.data.length === 0) {
    return null
  }
  // console.log("after check", pagesData.data, pagesData.data[0]);
  // Return the first item since there should only be one result per slug
  return eventsData.data
}

/**
 *  ARTICLES
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
 export async function getArticlesPaginated(sort, locale, preview, field, page, pageSize) {
  
  // const slug = params.slug.join('/')

  let query = qs.stringify({
    // filters: {
    //   fields:{
    //     tag:{
    //       $eq: field
    //     }
    //   }
    // },
    sort: [ sort ? sort : "id:desc"],
    pagination:{
      page: page,
      pageSize: pageSize,
    }
  }, {
    encodeValuesOnly: true,
  });

  if(field !== "all"){
    query = qs.stringify({
      filters: {
        fields:{
          tag:{
            $eq: field
          }
        }
      },
      sort: [ sort ? sort : "id:desc"],
      pagination:{
        page: page,
        pageSize: pageSize,
      }
    }, {
      encodeValuesOnly: true,
    });
  }

  // Find the pages that match this slug
  // const pagesData = await fetchAPI(
  //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //     preview ? '&publicationState=preview' : ''
  //   }`
  // )
  const projectsData = await fetchAPI(
    `/articles?${query}&locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // Make sure we found something, otherwise return null
  if (projectsData.data == null || projectsData.data.length === 0) {
    return null
  }
  // console.log("after check", pagesData.data, pagesData.data[0]);
  // Return the first item since there should only be one result per slug
  return projectsData.data
}


 /**
 *  ARTICLES RELATED
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
  export async function getArticlesRelated(params, locale, preview, date, page, pageSize, currentSlug, time) {
  
    // const slug = params.slug.join('/')
  
    let query = qs.stringify({
      filters: {
        // date:{
        //   $gt: date
        // },
        // time:{
        //   $gte: time
        // },
        slug:{
          $ne: currentSlug
        }
      },
      sort: ["createdAt:desc"],
      pagination:{
        page: page,
        pageSize: pageSize,
      }
    }, {
      encodeValuesOnly: true,
    });
  
    // if(field !== "all"){
    //   query = qs.stringify({
    //     filters: {
    //       fields:{
    //         tag:{
    //           $eq: field
    //         }
    //       }
    //     },
    //     sort: ["createdAt:desc"],
    //     pagination:{
    //       page: page,
    //       pageSize: pageSize,
    //     }
    //   }, {
    //     encodeValuesOnly: true,
    //   });
    // }
  
    // Find the pages that match this slug
    // const pagesData = await fetchAPI(
    //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
    //     preview ? '&publicationState=preview' : ''
    //   }`
    // )
    const articlesData = await fetchAPI(
      `/articles?${query}&locale=${locale}&publicationState=live${
        preview ? '&publicationState=preview' : ''
      }`
    )
    // Make sure we found something, otherwise return null
    if (articlesData.data == null || articlesData.data.length === 0) {
      return null
    }
    // console.log("after check", pagesData.data, pagesData.data[0]);
    // Return the first item since there should only be one result per slug
    return articlesData.data
  }





/**
 *  ARTICLE DATA
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
 export async function getArticleData(params, locale, preview) {
  
  const slug = params.slug.join('/')

  const query = qs.stringify({
    filters: {
      slug: {$eq: slug}
    },
  }, {
    encodeValuesOnly: true,
  });

  // console.log(`/articles?${query}&locale=${locale}&publicationState=live${
  //   preview ? '&publicationState=preview' : ''
  // }`)

  // Find the pages that match this slug
  // const pagesData = await fetchAPI(
  //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //     preview ? '&publicationState=preview' : ''
  //   }`
  // )
  const articleData = await fetchAPI(
    `/articles?${query}&locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // console.log(articleData)
  
  // Make sure we found something, otherwise return null
  if (articleData.data == null || articleData.data.length === 0) {
    // console.log("article null")
    return null
  }
  // console.log("after check", pagesData.data, pagesData.data[0]);
  // Return the first item since there should only be one result per slug
  return articleData.data[0]
}


/**
 *  EVENT DATA
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
 export async function getEventData(params, locale, preview) {
  
  const slug = params.slug.join('/')

  const query = qs.stringify({
    filters: {
      slug: {$eq: slug}
    },
  }, {
    encodeValuesOnly: true,
  });

  // console.log(`/events?${query}&locale=${locale}&publicationState=live${
  //   preview ? '&publicationState=preview' : ''
  // }`)

  // Find the pages that match this slug
  // const pagesData = await fetchAPI(
  //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //     preview ? '&publicationState=preview' : ''
  //   }`
  // )
  const eventData = await fetchAPI(
    `/events?${query}&locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // console.log(eventData)
  
  // Make sure we found something, otherwise return null
  if (eventData.data == null || eventData.data.length === 0) {
    // console.log("event null")
    return null
  }
  // console.log("after check", pagesData.data, pagesData.data[0]);
  // Return the first item since there should only be one result per slug
  return eventData.data[0]
}


/**
 *  PROJECT DATA
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
 export async function getProjectData(params, locale, preview) {
  
  const slug = params.slug.join('/')

  const query = qs.stringify({
    filters: {
      slug: {$eq: slug}
    },
  }, {
    encodeValuesOnly: true,
  });

  // console.log(`/projects?${query}&locale=${locale}&publicationState=live${
  //   preview ? '&publicationState=preview' : ''
  // }`)

  // Find the pages that match this slug
  // const pagesData = await fetchAPI(
  //   `/pages?slug=${slug}&locale=${locale}&publicationState=live${
  //     preview ? '&publicationState=preview' : ''
  //   }`
  // )
  const eventData = await fetchAPI(
    `/projects?${query}&locale=${locale}&publicationState=live${
      preview ? '&publicationState=preview' : ''
    }`
  )
  // console.log(eventData)
  
  // Make sure we found something, otherwise return null
  if (eventData.data == null || eventData.data.length === 0) {
    // console.log("project null")
    return null
  }
  // console.log("after check", pagesData.data, pagesData.data[0]);
  // Return the first item since there should only be one result per slug
  return eventData.data[0]
}