
import React, {useContext} from 'react'
import useMousePosition from "../../hooks/useMousePosition";
import { MouseContext } from "../../context/mouseContext";
import gsap from "gsap";
// import { animation } from 'tailwindcss/defaultTheme';
import { isSafariBrowser, isFirefoxBrowser } from '../../utils/isBrowser';
import GradientBalls from '../gradient/GradientBalls';
import GradientBackground from '../gradient/GradientBackground';
import { Color, Vector2 } from 'three';
import Link from '../icons/Link';

const CustomCursor = () => {

  const { cursorType, cursorWord, playAnimation } = useContext(MouseContext);
  const { x, y } = useMousePosition();
  const [timeline, setTimeline] = React.useState(gsap.timeline())
  const dotRef = React.useRef();
  const wordRef = React.useRef();
  // const filterRef = React.useRef();
  // const turbulenceRef = React.useRef();
  // const displacementRef = React.useRef();
  const primitiveValues = React.useRef({scale: 0});
  const animationPlaying = React.useRef(false);


  const pos = new Vector2(0.5,0.5);
  const color1 = new Color("#FD745C").convertLinearToSRGB().getHex();
  const color2 = new Color("#b4ccd6").convertLinearToSRGB().getHex();

  // React.useEffect(()=>{

  //   if(!isSafariBrowser()){
  //     if(!isFirefoxBrowser()){
  //       dotRef.current.style.backdropFilter = "sepia(5) grayscale(1) invert(1) contrast(1)";
  //       dotRef.current.style.webkitBackdropFilter = "sepia(5) grayscale(1) invert(1) contrast(1)";
  //       dotRef.current.style.background = "#000";
  //     }else{
  //       dotRef.current.style.background = "#fff"; 
  //     }
  //   }else{
  //     dotRef.current.style.background = "#fff";
  //     dotRef.current.style.backdropFilter = "sepia(5) grayscale(1) invert(1) contrast(1)";
  //     dotRef.current.style.webkitBackdropFilter = "sepia(5) grayscale(1) invert(1) contrast(1)";
  //     // dotRef.current.style.backdropFilter = "none";
  //     dotRef.current.style.transitionProperty = "width, height";
  //     dotRef.current.style.transitionDuration = "300ms, 300ms";
  //     dotRef.current.style.mixBlendMode = "none";


  //     wordRef.current.style.transitionProperty = "width, height";
  //     wordRef.current.style.transitionDuration = "300ms, 300ms";
  //   }
  //   setTimeline(gsap.timeline({
  //     paused: true,
  //     onStart: () => {
  //       // dotRef.current.style.filter = `url(#svg-filter)`;  
  //       animationPlaying.current = true;
  //     },

  //     onComplete: () => {
  //       // dotRef.current.style.filter = `sepia(5)
  //       //   /* saturate(100)  */
  //       //   invert(0)     
  //       //   grayscale(1)  
  //       //   contrast(1);`;
  //         animationPlaying.current = false;
  //     }
  //   }));
  // }, []);

  // React.useEffect(()=>{
  //   // console.log("performance check")
  //   if(!isSafariBrowser() && !isFirefoxBrowser()){
  //     // console.log("not safari")
  //     if(playAnimation && !animationPlaying.current){
  //       primitiveValues.current = {scale: 0};
  //       timeline.set(dotRef.current, {filter: `url(#svg-filter)`})
  //       timeline.eventCallback('onUpdate', () => {
          
  //         document.getElementById("svg-filter-displacement").scale.baseVal = primitiveValues.current.scale;
          
  //       });
  //       // timeline.set(dotRef.current, {filter: `url(#svg-filter)`, webkitFilter: `url(#svg-filter)`})
        
  //       timeline.from(primitiveValues.current, { 
  //           duration: 1,
  //           ease: 'Expo.easeOut',
  //           scale: 180
  //       });

  //       // timeline.set(dotRef.current, {filter: `none`, clearProps:"filter"})
  //       timeline.play();
  //     }else {
  //       timeline.clear();
  //       timeline.set(dotRef.current, {
  //         filter:"none",
  //         clearProps:"filter"
  //       })
        
  //       animationPlaying.current = false;
        
  //     }

  //   }
  // }, [playAnimation]);

  return (
    <>
      <div
        ref={dotRef}
        className={`hidden lg:block custom-cursor-dot relative background-color ${cursorType}`}
        style={{overflow:"hidden"}}
        // style={{ left: `${x}px`, top: `${y}px` }}
        // style={{ WebkitTransform: `translate(calc(${x}px - 50%), calc(${y}px - 50%)`, transform: `translate(calc(${x}px - 50%), calc(${y}px - 50%)` }}
      >
        {/* <div className="w-full h-full" style={{position:"absolute", top:"0", left:"0"}}>
          <GradientBackground colorStart={color1} colorEnd={color2}  positionBlob={pos} scale={1.5} opacityFactor={1.0} circleScale={15} noAnimation={true} />
        </div> */}
        <div className="noise" style={{backgroundImage: `url("./noise-web.webp")`, pointerEvents:"none"}}></div>
      </div>

      <div
        ref={wordRef}
        className={`hidden lg:block custom-cursor-word relative ${cursorWord ? "active" : ""}`}
        // style={{ left: `${x}px`, top: `${y}px` }}
        // style={{ WebkitTransform: `translate(calc(${x}px - 50%), calc(${y}px - 50%)`, transform: `translate(calc(${x}px - 50%), calc(${y}px - 50%)` }}
      >
          {/* <span style={{color:"white"}}>{cursorWord}</span> */}
          {/* <span>{cursorWord}</span> */}
          <span><Link/></span>
      </div>
    </>
  );
};

export default CustomCursor;