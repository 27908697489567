import React, { useEffect, useRef, useState, forwardRef, useLayoutEffect, createRef, Suspense } from 'react'
import { Canvas, useFrame, useThree, useControls } from '@react-three/fiber'
import { BlobMaterial } from './BlobMaterial'
import * as THREE from 'three'
// import { Sphere, OrthographicCamera, PresentationControls, MeshWobbleMaterial, MeshDistortMaterial, MeshReflectorMaterial, Sparkles, Stars, Stage, Bounds, OrbitControls, AdaptiveDpr } from "@react-three/drei";
// import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'
import { Color, Vector2, Vector3 } from 'three';

// import { useSpring, animated } from '@react-spring/three'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";


const  ShaderPlane = ({colorStart="orange", colorEnd = "lightgrey", positionBlob, scale, positionMesh, initGsapAnimation, killGsapAnimation, keyBlob, opacity, timeFactor })=> {
  const ref = useRef()
  const meshRef = useRef()


  const {x, y} = {x: positionBlob.x, y: positionBlob.y};
  const { width, height } = useThree((state) => state.viewport )
  // console.log("width:", width, " height:", height)
  const color1 = new Color(colorStart)
  const color2 = new Color(colorEnd)
  useFrame((state, delta) => {
    ref.current.time += delta;
    // console.log(colorStart)
    // ref.current.x += Math.cos(state.clock.elapsedTime * 0.5) * 0.0015;
    // ref.current.y += Math.cos(state.clock.elapsedTime * 0.5) * 0.0015;
    // meshRef.current.rotation.z += delta
    // materialRef.current.scale = scale;
  })

  useEffect(()=>{
    // console.log("add animation")
    // console.log(positionBlob)
    initGsapAnimation(ref, keyBlob);

    // alert("way oyt there")

    // return () => {
      // killGsapAnimation();
    // }
  }, [])

  // useEffect(()=>{
    // console.log("colorStart",colorStart)
    // console.log("colorEnd",colorEnd)
    // console.log("plane render")
  // })

  // const onUpdate = (self) => {
  //   console.log(ref.current?.colorStart)
  //   console.log("update mesh")
  // }

  return (
    <mesh ref={meshRef} position={positionMesh} scale={[width, height, 1]}>
      <planeBufferGeometry args={[1, 1, 1]} />
      {/* We use the materials module 🔑 to allow HMR replace */}
      {/* <meshBasicMaterial color={new Color(colorStart)}/> */}
      <blobMaterial ref={ref} key={keyBlob} colorStart={color1} colorEnd={color2} x={x} y={y} positionBlob={positionBlob} scale={scale} opacityFactor={opacity} timeFactor={timeFactor} transparent clampMin={0.0}/>
    </mesh>
  )
};

ShaderPlane.displayName="ShaderPlane"
export default ShaderPlane