import PropTypes from "prop-types"
import { linkPropTypes, mediaPropTypes } from "/utils/types"
import NextImage from "./image"
import CustomLink from "./custom-link"
import ButtonLink from "./button-link"
import React from "react"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { getButtonAppearance } from "/utils/button"
import Divider from "./divider"
import LocaleSwitch from "../locale-switch"

const Footer = React.memo( ({ footer, colorFooter, pageContext }) => {
  const myTween = React.useRef();
  const ref = React.useRef();

  React.useEffect(()=>{

    gsap.registerPlugin(ScrollTrigger);

    myTween.current = gsap.timeline({ 
      scrollTrigger: {
        trigger: ref.current,
        start: '-20% top',
        // end:"-20% top",
        // once: false,
        toggleActions: "play none none reverse",
        // scrub: 1,
        // markers:true,
        // start: start,
        // end: '90%'
      }
    });

    myTween.current.fromTo(ref.current.querySelector(".clip-path-animation"),{
      // clipPath: "circle(max(55vw,50%) at 50% max(55vw, 50%))",
      borderRadius: "100vw 100vw 0 0",
      duration: 1.0,
      ease:"Power3.easeOut"
    },{
      // clipPath:"circle(max(55vw,100%) at 50% max(55vw, 50%))",
      borderRadius: "0",
      duration: 1.0,
      ease:"Power3.easeOut"
    })

    return ()=>{
      myTween.current.kill();
    }
  },[])
// clipPath: "circle(max(55vw,50%) at 50% max(55vw, 50%));"
// circle(max(40vw,40%) at 50% max(100vw, 100%))

  return (
    <footer ref={ref} className="w-full p-0 m-0" style={{position:"relative", zIndex:"2", mixBlendMode:"normal"}} id="footer">
        {/* <div className="circle clip-path-animation" style={{position:"absolute", top:"0",left:"0", backgroundColor: colorFooter, clipPath: "circle(max(55vw,50%) at 50% max(55vw, 50%))", minHeight:"100%", zIndex:"-1"}}></div> */}
        
      <div className="container m-0" style={{position:"relative", height:"100svh", overflow:"hidden"}}>
        <div className="relative overflow-hidden circle clip-path-animation" style={{backgroundColor: colorFooter}}>
          <div className="noise" style={{backgroundImage: `url("/noise-web.webp")`, pointerEvents:"none"}}></div>
        </div>
        <div className="h-full flex flex-col items-center justify-center text-center" style={{paddingBottom:"25svh"}}>
          <div>
            <h1 className="mb-6 pt-20 text-5xl md:text-6xl" style={{fontFamily:"Larish"}}>{footer.title}</h1>
            <p className="" style={{maxWidth:"45ch"}}>{footer.text}</p>
            {/* <CustomLink link={{url:"/"}}>Contacte-nos</CustomLink> */}
            <ButtonLink
              button={{text: footer.buttonText, url: `mailto:${footer.email ?? "geral@couto-mixto.com"}`}}
              appearance={getButtonAppearance("secondary", "light")}
              compact
            />
          </div>
        </div>
      </div>

      <div className="relative w-full" style={{backgroundColor: colorFooter, marginTop:"-25svh"}}>
          <div className="noise" style={{backgroundImage: `url("/noise-web.webp")`, pointerEvents:"none"}}></div>
        <div className="container pb-10">
          <Divider color="black"/>
        </div>
        <nav className="container grid-cols-4 pb-10 md:grid">
          <div className="mb-10 md:mb-0">
            {footer?.logo && (
              <NextImage width="120" height="33" media={footer.logo} style={{filter:"invert(1)"}}/>
            )}
            <LocaleSwitch pageContext={pageContext}/>
          </div>
          {footer?.columns?.map((footerColumn) => (
            <div
              key={footerColumn.id}
              className="pt-5 mb-10 md:px-5 md:pt-0 md:pb-5 footer-col-item"
              // style={{borderLeft:"1px solid #00000033"}}
            >
              <div className="mb-5 md:hidden divider-wrapper" style={{maxWidth:"6ch"}}>
                <Divider color="black"/>
              </div>
              <p className="mb-5 font-semibold" style={{fontSize:"16px", fontWeight:"500"}}>
                {footerColumn.title}
              </p>
              <ul className="mt-2">
                {footerColumn.links.map((link, index) => {
                  return (
                      <li
                        key={index}
                        className="px-1 py-1 mt-1 -mx-1 md:mt-3"
                        style={{fontSize:"14px", lineHeight:"1.4", fontWeight:"300", maxWidth:"20ch"}}
                      >
                        <CustomLink link={link}>{link?.text}</CustomLink>
                      </li>
                  )
                })}
              </ul>
            </div>
          ))}
        </nav>
        {/* <div className="py-10 text-sm text-gray-700">
          <div className="container">{footer?.smallText}</div>
        </div> */}
      </div>
    </footer>
  )
})

Footer.propTypes = {
  footer: PropTypes.shape({
    logo: mediaPropTypes.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        title: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(linkPropTypes),
      })
    ),
    smallText: PropTypes.string.isRequired,
  }),
}
Footer.displayName = "Footer"

export default Footer
