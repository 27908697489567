import { getStrapiMedia } from "/utils/media"
import Image from "next/image"
import PropTypes from "prop-types"
import { mediaPropTypes } from "/utils/types"
import { getStrapiURLRaw } from "../../utils/api"

const NextImage = ({ media, ...props }) => {
  // console.log(media)
  // if(!media.width || !media.height){
    // console.log("no width or no height", media)
  // }
  if(!media?.url){
    // console.log("nothing here")
    return(<></>)
  }

  if(props.log){
    // console.log(media)
  }
  const { url, alternativeText } = media

  const loader = ({ src, width, quality }) => {

    return getStrapiMedia(src)
  }
  const strapiUrl = getStrapiURLRaw();

  // The image has a fixed width and height
  if (props.width && props.height) {
    return (
      <Image src={`${strapiUrl}${url}`} alt={alternativeText || ""} {...props} />
    )
  }

  // The image is responsive
  return (
    <Image
      // loader={loader}
      layout="raw"
      width={media.width ?? 10}
      height={media.height ?? 10}
      // objectFit="cover"
      // priority
      // loading="eager"
      src={`${strapiUrl}${url}`}
      alt={alternativeText || ""}
      {...props}
    />
  )
}

// Image.propTypes = {
//   media: mediaPropTypes.isRequired,
//   className: PropTypes.string,
// }

export default NextImage
