import { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import { useRouter } from "next/router"

import { getButtonAppearance } from "/utils/button"
import { mediaPropTypes, linkPropTypes, buttonLinkPropTypes } from "/utils/types"
import { MdMenu } from "react-icons/md"
import MobileNavMenu from "./mobile-nav-menu"
import ButtonLink from "./button-link"
import NextImage from "./image"
import CustomLink from "./custom-link"
import LocaleSwitch from "../locale-switch"
import { MdChevronRight } from "react-icons/md"
import { TbPlus } from "react-icons/tb";
import { lockScroll, scrolling, unlockScroll, useOnClickOutside } from "../../utils/hooks"

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Divider from "./divider"
// import { useOnClickOutside } from "/utils/hooks"

const Navbar = ({ navbar, pageContext, isBlack = false }) => {
  // console.log("navbar", pageContext);
  const router = useRouter()
  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false)


	const [scrollPogress, setScrollPogress] = useState(0);
	const [backgroundActive, setBackgroundActive] = useState(false);
	const [navbarActive, setNavbarActive] = useState(true);
	const [sideMenuActive, setSideMenuActive] = useState(false);
	const [navbarBlack, setNavbarBlack] = useState(false);
  const sideMenuRef = useRef();
  const navRef = useRef();
  const [currentActiveMenu, setCurrentActiveMenu] = useState(-1);


  useOnClickOutside(navRef, () => {
    setTimeout(()=>{
      if(sideMenuActive){
        changeDropdownMenu(-1)
      }
    }, 100);
  })

  function changeDropdownMenu(id){
    console.log(id)
    if((sideMenuActive && currentActiveMenu == id) || id == -1){
      // console.log(window.scrollY);
      if(window.scrollY <= 100){
        setNavbarActive(true)
        setNavbarBlack(false)
        setBackgroundActive(false);
      }
      setSideMenuActive(false)
      setCurrentActiveMenu(-1)
      // unlockScroll()
      scrolling.enable()
    }else{
      setNavbarActive(true)
      setNavbarBlack(true)
      setBackgroundActive(true);
      setSideMenuActive(true)
      setCurrentActiveMenu(id)
      // lockScroll()
      scrolling.disable()
    }
  }

  function openMenu(progress, direction){
    // console.log(document.querySelector(".side-menu").classList.contains("side-menu-open"))
    // console.log(sideMenuActive)
    if(!sideMenuActive){
      if (progress <= 0.005) {
        // console.log("top")
        setNavbarActive(true)
        setNavbarBlack(false)
        setBackgroundActive(false);
      }else{
        if (direction === -1 /* && isHeaderHidden === true */) {
          // console.log("not top")
          setNavbarActive(true)
          setNavbarBlack(true)
          setBackgroundActive(true);
        }
        if (direction === 1 /* && isHeaderHidden === false */) {
          setNavbarActive(false)
          setBackgroundActive(false);
        }
      }
    }

  }

  useEffect(() => {
    // ScrollTrigger.normalizeScroll(true);
		gsap.registerPlugin(ScrollTrigger);
		ScrollTrigger.create({
      id:"menu_scroll_trigger",
			trigger: "#__next",
			start: "top top",
			end: "100%",
      onUpdateParams: ["sideMenuActive"],
      // sideMenuActive: sideMenuActive,
			onUpdate: ({ progress, direction }) => {

				openMenu(progress, direction)
				setScrollPogress(progress);
        // console.log(progress)
			},
      
		});

    return () => {
      if(ScrollTrigger.getById("menu_scroll_trigger")){
        ScrollTrigger.getById("menu_scroll_trigger").kill();
      }
    }
	}, [sideMenuActive, router.asPath]);

  useEffect(()=>{
    setNavbarBlack(isBlack)
  }, [isBlack])

  return (
    <>

      <div style={{position:"fixed", top:"0", left:"0", width: "100%", zIndex:"99"}} ref={navRef}>
        <div className="navbar-background noise" style={{backgroundImage: `url("/noise-web.webp")`, position:"absolute", top:"0", bottom:"0", opacity:"0.2", zIndex:"-1", transform: backgroundActive ? "" : "translateY(-100%)", transition: "0.3s"}}></div>
        <div className="w-full h-full navbar-background" style={{background: "#D4D6D3", position:"absolute", top:"0", bottom:"0", opacity:"1", zIndex:"-2", transform: backgroundActive ? "" : "translateY(-100%)", transition: "0.3s"}}></div>
          
        {/* {notificationBanner && bannerIsShown && (
          <NotificationBanner
            data={notificationBanner}
            closeSelf={() => setBannerIsShown(false)}
          />
        )} */}
        {/* The actual navbar */}
        <nav className="py-0 md:py-2 " style={{ clipPath: navbarActive ? "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" : "polygon(0 0, 100% 0, 100% 0%, 0% 0%)", transition: "0.3s" }}>
          <div className="container flex flex-row items-center justify-between">
            {/* <div className="noise" style={{backgroundImage: `url("./noise-web.webp")`, pointerEvents:"none", width:"100%", height:"100%", opacity:"0.5"}}></div> */}
            {/* Content aligned to the left */}
            <div className="flex flex-row items-center">
              <CustomLink link={{url:"/"}}>
                <div className="py-4 transition-all cursor-pointer" style={{filter: navbarBlack ? "invert(1)": ""}}>
                  <NextImage width="130" height="33" media={navbar.logo} />
                </div>
              </CustomLink>
              
            </div>
            <div className="flex navbar-links-wrapper">
              {/* List of links on desktop */}
              <ul className="flex-row items-center justify-between hidden gap-4 px-5 list-none md:flex md:w-full">
                {navbar.links.map((navLink) => {
                  if(!navLink.isDropdown){
                    return (
                      <li key={navLink.id} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}>
                        <CustomLink link={navLink} locale={router.locale} hovertext={navLink.text} extraFunction={() => {
                                    setSideMenuActive(false)
                                    openMenu(0,0)
                                  }}>
                          <div className={`link-wrapper hover:text-gray-900 px-2 py-1 ${navbarBlack ? "text-black" : "text-white"} transition-all`}>
                            <div className="link link--elara">
                              <span>{navLink.text}</span>
                            </div>
                          </div>
                        </CustomLink>
                      </li>
                    )
                  }
                  return (
                    <li key={navLink.id} menuelementid={navLink.id} onClick={() => {changeDropdownMenu(navLink.id)}} style={{cursor:"pointer"}}>
                      <div link={navLink} locale={router.locale} hovertext={navLink.text}>
                        <div className={`link-wrapper hover:text-gray-900 px-2 py-1 ${sideMenuActive && currentActiveMenu == navLink.id ? "link-wrapper-active" : ""} ${navbarBlack ? "text-black" : "text-white"} transition-all`}>
                          <div className="link link--elara">
                            <span style={{display:"inline-block"}}>{navLink.text}</span> <TbPlus className="w-auto h-4"  style={{display:"inline-block"}}/>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
              {/* Locale Switch Mobile */}
              {/* {pageContext.localizedPaths && (
                <div className={`md:hidden ${navbarBlack ? "text-black" : "text-white"} transition-all`}>
                  <LocaleSwitch pageContext={pageContext} />
                </div>
              )} */}
              {/* Hamburger menu on mobile */}
              <button
                onClick={() => setMobileMenuIsShown(true)}
                className="block p-1 md:hidden"
              >
                <MdMenu className="w-auto h-8" />
              </button>
              {/* CTA button on desktop */}
              {/* {navbar.button && (
                <div className="hidden md:block">
                  <ButtonLink
                    button={navbar.button}
                    appearance={getButtonAppearance(navbar.button.type, "light")}
                    compact
                  />
                </div>
              )} */}
              {/* Locale Switch Desktop */}
              {/* {pageContext.localizedPaths && (
                <div className={`hidden md:block ${navbarBlack ? "text-black" : "text-white"} transition-all`}>
                  <LocaleSwitch pageContext={pageContext} />
                </div>
              )} */}
            </div>
          </div>
        </nav>

        <div className="hidden md:block" style={{position:"absolute", top:"calc(100%)", right:"0", zIndex:"99", width:"50%", height:"calc(100svh - 100%)", clipPath: sideMenuActive ? "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" : "polygon(100% 0, 100% 0, 100% 100%, 100% 100%)", transition: "0.75s" }}>
          <div className="navbar-background noise" style={{backgroundImage: `url("/noise-web.webp")`, position:"absolute", top:"0", bottom:"0", opacity:"0.2", zIndex:"-1", transform: sideMenuActive ? "" : "translateX(100%)", transition: "0.75s"}}></div>
          <div className="w-full h-full navbar-background" style={{background: "#D4D6D3", position:"absolute", top:"0", bottom:"0", opacity:"1", zIndex:"-2", borderLeft:"1px solid #91919188", borderTop:"2px solid #91919188", transform: sideMenuActive ? "" : "translateX(100%)", transition: "0.75s"}}></div>

          <div className={`container h-full flex flex-row items-center justify-between side-menu ${sideMenuActive ? "side-menu-active" : ""}`} ref={sideMenuRef} style={{ clipPath: sideMenuActive ? "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" : "polygon(100% 0, 100% 0, 100% 100%, 100% 100%)", transition: "0.75s" }}>
            <div className="relative w-full h-full">
                {navbar.links.map((navLink) => {
                    if(navLink.isDropdown){
                      return (
                        <div className={`absolute top-0 left-0 h-full w-full transition-opacity ${currentActiveMenu == navLink.id ? "opacity-100" : "opacity-0 pointer-events-none"}`} key={navLink.id} menuelementid={navLink.id}>
                          <div className="flex w-full py-10">
                            <ul className={"flex flex-col items-baseline w-full gap-5 mb-10 text-xl list-none"} >
                              {navLink.links.map((navSubLink) => {
                                return (
                                  <li key={navSubLink.id} className="block w-full">
                                    <CustomLink link={navSubLink} extraFunction={() => {
                                        setSideMenuActive(false)
                                        openMenu(0,0)
                                      }} >
                                      <div className="flex flex-row items-center justify-between py-3 link-wrapper hover:text-gray-900" style={{fontWeight:"400"}}>
                                        <div className="link link--elara">
                                          <span>{navSubLink.text}</span>
                                        </div>
                                        {/* <MdChevronRight className="w-auto h-8" /> */}
                                        {/* <TbPlus className="w-auto h-6" /> */}
                                      </div>
                                    </CustomLink>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      )
                    }
                })}
            </div>
          </div>
        </div>

        {/* Mobile navigation menu panel */}
        {mobileMenuIsShown && (
          <MobileNavMenu
            navbar={navbar}
            closeSelf={() => setMobileMenuIsShown(false)}
          />
        )}

      </div>
    </>
  )
}

Navbar.propTypes = {
  navbar: PropTypes.shape({
    logo: PropTypes.shape({
      image: mediaPropTypes,
      url: PropTypes.string,
    }),
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
  initialLocale: PropTypes.string,
}

export default Navbar
