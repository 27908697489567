// Decide what the button will look like based on its type (primary or secondary)
// and on its background (light or dark).
export function getGradientColors() {
  // agricultura
  return {
    agricultura: {
      color1: "#ada060",
      color2: "#f8d498",
      colorFooter: "#ada060",
    },
    agriculture: {
      color1: "#ada060",
      color2: "#f8d498",
      colorFooter: "#ada060",
    },
    // cultura
    cultura: {
      color1: "#D8FFA2",
      color2: "#AFC3C7",
      colorFooter: "#D8FFA2",
    },
    culture: {
      color1: "#D8FFA2",
      color2: "#AFC3C7",
      colorFooter: "#D8FFA2",
    },
    // industria
    industria: {
      color1: "#A3A8BF",
      color2: "#A0ADB2",
      colorFooter: "#A0ADB2",
    },
    industry: {
      color1: "#A3A8BF",
      color2: "#A0ADB2",
      colorFooter: "#A0ADB2",
    },
    // Sustentabilidade
    sustentabilidade: {
      color1: "#7DB898",
      color2: "#82CF9E",
      colorFooter: "#7DB898",
    },
    sustainability: {
      color1: "#7DB898",
      color2: "#82CF9E",
      colorFooter: "#7DB898",
    },
    // Vinhos
    vinhos: {
      color1: "#9F5E53",
      color2: "#541343",
      colorFooter: "#9F5E53",
    },
    wines: {
      color1: "#9F5E53",
      color2: "#541343",
      colorFooter: "#9F5E53",
    },
    // homepage MAIN GRADIENT
    base:{
      color1: "#FF4B2A",
      color2: "#AFC3C7",
      colorFooter: "#AFC3C7",
    },
    homepage:{
      color1: "#FF4B2A",
      color2: "#AFC3C7",
      colorFooter: "#AFC3C7",
    },
    sobre_nos:{
      color1: "#FF4B2A",
      color2: "#AFC3C7",
      colorFooter: "#FFCF6F",
    }
  }
}


export function getBannerGradientColors() {

  return [
    // "#6B514A",
    // "#A0ADB2",
    // "#f8d498",
    "#D8FFA2",
    "#F55C30",
    "#1BBEAF",
  ]

}