import React, { createContext, useRef, useContext } from "react"
import { TransitionGroup, Transition, SwitchTransition } from "react-transition-group"
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TransitionElementContext } from "../context/transitionElementContext";
import { scrolling } from "../utils/hooks";

const blockScroll = (fromHeaderMenu = false) => {
    //prevent scrolling for loading
    // const bodyOriginalOverflow = document.body.style.overflow;
    // const bodyOriginalPaddingRight = document.body.style.paddingRight;

    // let scrollbarWidthPx = window.innerWidth - document.body.clientWidth;

    // // Prevent scrolling on mount
    // document.body.style.overflow = "hidden";
    // document.body.style.position = "relative";
    // document.body.style.paddingRight = `${scrollbarWidthPx}px`;
    // let padding = window.innerWidth >= 1600 ? "5rem" : "1.25rem";
    // // console.log(window.innerWidth, scrollbarWidthPx, padding);
    // // document.getElementById("transition-element").style.paddingRight= `calc(${padding} + ${scrollbarWidthPx}px)`;
    // document.getElementById("transition-element").style.paddingRight= `calc(${scrollbarWidthPx}px)`;
    // // document.getElementById("navbar-antecamara").style.paddingRight = `${scrollbarWidthPx}px`;

    document.querySelector("html").classList.add("noscroll")
    scrolling.disable();
}

const unblockScroll = () => {
    // allow scrolling
    // let scrollbarWidthPx = window.innerWidth - document.body.clientWidth;
    // document.body.style.overflow = "";
    // document.body.style.paddingRight = "";
    // document.getElementById("__next").style.paddingRight = 0;
    // document.getElementById("navbar-antecamara").style.paddingRight = 0;
    document.querySelector("html").classList.remove("noscroll")
    scrolling.enable();
}


export const PageTransitionContext = createContext({
    timelineLoading: null,
    timelineEnter: null,
    timelineExit: null,
    isLoaded: null,
    playAnimation: null,
    addToTimeline: () => {},
});

const completeCall = (target, parent = null) => {
	target &&
		gsap.set(target, {
			clearProps: "position, width, transform, top, left, visibility, overflow, opacity, z-index",
            // onComplete:() => {
            //     ScrollTrigger.refresh()
            // }
		});
	parent &&
		gsap.set(parent, {
			clearProps: "transform, overflow, position, min-height, z-index",
		});
	target && gsap.set(target, { clearProps: "transform, overflow, z-index" });

    target.style = "";
	// let anchor = getAnchor();
	// if (anchor) {
	// 	var anchor1 = document.getElementById(anchor);
	// 	var posTop = anchor1.offsetTop;

	// 	gsap.to(window, 1, { scrollTo: posTop });
	// }
};

const PageTransition = ({ children, path }) => {
    const { closeTransitionElementHandler, openTransitionElementHandler, fromHeaderMenu, isFromHeaderMenu } = useContext(TransitionElementContext);
    const timelineLoading = useRef(gsap.timeline());
    const timelineEnter = useRef(gsap.timeline());
    const timelineExit = useRef(gsap.timeline());
    const isLoadedRef = useRef(false);
    const playAnimationRef = useRef(false);
    // const [pageRefresh, setPageRefresh] = React.useState(0);

    React.useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);
        // console.log("loading")

        // blockScroll();
        window.scrollTo(0,0);
        scrolling.disable();
        timelineLoading.current = gsap.timeline();
        timelineEnter.current = gsap.timeline();
        timelineExit.current = gsap.timeline();

        timelineLoading.current.to("#transition-element",{
            // position:"absolute",
            autoAlpha:1,
            duration: 2.3,
            onComplete:()=>{
                isLoadedRef.current = true;
                closeTransitionElementHandler();
                window.scrollTo(0,0);
                scrolling.enable();
                unblockScroll(fromHeaderMenu);
                // // setPageRefresh(pageRefresh + 1);
                // ScrollTrigger.clearScrollMemory();
                // ScrollTrigger.getAll().forEach((element)=>{
                //     element.disable(true,true);
                // })
                // // window.scrollTo(0,0);
                // ScrollTrigger.getAll().forEach((element)=>{
                //     element.enable();
                // })
                // ScrollTrigger.refresh();
                // ScrollTrigger.update();
            }
        })

        return () => {
            if(timelineLoading.current){
                timelineLoading.current.kill()
            }
            if(timelineEnter.current){
                timelineEnter.current.kill()
            }
            if(timelineExit.current){
                timelineExit.current.kill()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const addToTimeline = (animation) => {
        // console.log("adding other animation")
        if(isLoadedRef.current){
            timelineEnter.current.add(animation);
        }else{
            timelineLoading.current.add(animation);
        }
    }

    const onEnterHandler = (node, isAppearing) => {
        // console.log("trigger enter transition", isAppearing);
        timelineEnter.current.killTweensOf(node);

        timelineEnter.current.set(node,{
            position:"absolute",
            overflow: "hidden",
            autoAlpha:0,
        })

        scrolling.disable();
        blockScroll(fromHeaderMenu);

        // timelineEnter.current.to(node,{
        //     // duration: 0.5,
        //     // // delay: fromHeaderMenu ? 0 : 1.5,
        //     // delay:1.5,
        //     autoAlpha: 1,
        //     ease: "Power.out",
        //     onComplete: () => {
        //         console.log("closeTransition")
        //         closeTransitionElementHandler(timelineEnter);
        //     }
        // })

        // window.scrollTo(0,0);

        timelineEnter.current.to(node,{
            duration: 0.1,
            // delay: fromHeaderMenu ? 0 : 1.5,
            delay:0.1,
            autoAlpha: 1,
            ease: "Power.out",
            onComplete: (node) => {
                // console.log("after enter")
                window.scrollTo(0,0);
                completeCall(node);
                scrolling.enable();
                unblockScroll();
                closeTransitionElementHandler();
                // playAnimationRef.current = true
                // ScrollTrigger.clearScrollMemory();
                ScrollTrigger.refresh();
                ScrollTrigger.update();
                // console.log(ScrollTrigger.getAll());
                // setPageRefresh(pageRefresh + 1)
                // isFromHeaderMenu(false);
            },
            onCompleteParams: [node],
        })


    };

    const onExitHandler = (node) => {
        playAnimationRef.current = false;
        // window.history.scrollRestoration = "manual"
        // console.log("trigger exit transition");
        if(!fromHeaderMenu){
            openTransitionElementHandler(timelineEnter);
        }
        ScrollTrigger.clearScrollMemory();
        ScrollTrigger.getAll().forEach((element)=>{
            element.disable(false,true);
        })

        let scrollbarWidthPx = window.innerWidth - document.body.clientWidth;
        // node.querySelector("#main").style.paddingRight = scrollbarWidthPx + 'px';
        // console.log(scrollbarWidthPx)
        node.style.position = "absolute";
        node.style.zIndex = 1;
        node.style.top = -1 * window.scrollY + "px";
        // ScrollTrigger.getAll().forEach((element)=>{
        //     // element.clearScrollMemory();
        //     element.kill()
        // });

        scrolling.disable();
        blockScroll(fromHeaderMenu);
        
        

        timelineExit.current.to(node, {
            autoAlpha: 0,
            duration: 0.1,
            // delay:fromHeaderMenu ? 0 : 1.1,
            delay:0.1,
            onComplete: (node) => {
                // console.log("after close")
                // unblockScroll();
                // window.scrollTo(0,0);
                // ScrollTrigger.refresh();
            },
        })
    };
    
    return (
        <TransitionGroup component={null}>
            <Transition
                timeout={{
                    appear: 1400,
                    enter: 1400,
                    exit:800,
                }}
                key={path}
                onEnter={onEnterHandler}
                onExit={onExitHandler}
                unmountOnExit={true}
                mountOnEnter={false}
                // in={true}
                // enter={false}
                // exit={false}
                // onExit={
                //     () => {
                //         console.log("onExit")
                //     }
                // }
                onExiting={
                    () => {
                        // console.log("onExiting")
                    }
                }
                onExited={
                    () => {
                        // console.log("onExited")
                        // gsap.set("body", {
                        //     delay: 0.5,
                        //     onComplete: () => {
                        //         ScrollTrigger.refresh()
                        //         playAnimationRef.current = true;
                        //     }
                        // })
                    }
                }
                // onEnter={
                //     (node, isAppearing) => {
                //         console.log("onEnter", isAppearing);
                //     }
                // }
                onEntering={
                    () => {
                        // console.log("onEntering")
                    }
                }
                onEntered={
                    () => {
                        // console.log("onEntered")
                        // gsap.set("body", {
                        //     delay: 0.5,
                        //     onComplete: () => {
                        //         ScrollTrigger.refresh()
                        //         playAnimationRef.current = true;
                        //     }
                        // })
                    }
                }
                component={null}
            >
                <PageTransitionContext.Provider
                    value={{
                        timelineLoading: timelineLoading.current,
                        timelineEnter: timelineEnter.current,
                        timelineExit: timelineExit.current,
                        isLoaded: isLoadedRef.current,
                        addToTimeline: addToTimeline,
                        playAnimation: playAnimationRef.current,
                    }}
                >
                    <div className="w-full layout-wrapper">
                        {children}
                    </div>
                </PageTransitionContext.Provider>
            </Transition>
        </TransitionGroup>
    )
  }



export default PageTransition