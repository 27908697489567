import { useEffect, useRef, Suspense, memo } from 'react'
import { Canvas } from '@react-three/fiber'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import ShaderPlane from './ShaderPlane';

const GradientBackground = memo(({colorStart, colorEnd, positionBlob, scale, opacityFactor, circleScale, noAnimation = false}) => {

  // const ballProps = useState(() => ({x: 0, y: 0, z: -50, scale: 50}));
  const ballMaterialRef = useRef();
  const ballMaterialRef2 = useRef();
  const ballMaterialRef3 = useRef();
  const ballMaterialRef4 = useRef();
  const ballMaterialRef5 = useRef();
  const myTween = useRef();
  const myTween2 = useRef();
  const firstRender = useRef(true);


  // const { concurrent, distributed } = useControls({
  //   concurrent: { value: true },
  //   distributed: { value: true },
  // });

  // const regress = useThree((state) => state.performance.regress)

  const initGsapAnimation = (ref, key) => {
    // console.log("add animation")
    // console.log(myTween.current.getChildren().length)
    // if(opacityFactor > 0){
      if(myTween.current.getChildren().length){
        // console.log("clear animation")
        myTween.current.clear(true);
      }
      
      // if(myTween2.current.getChildren().length){
      //   // console.log("clear animation")
      //   myTween2.current.clear(true);
      // }

      if(!noAnimation){
        // gsap.to(ref.current, {
        //   opacityFactor: opacityFactor,
        //   scale: scale,
        //   // duration:0.1,
        //   x:positionBlob.x,
        //   y:positionBlob.y,
        //   onComplete:()=>{

        //     ScrollTrigger.refresh()
        //     ScrollTrigger.update()
        //   }
        // })
        
        myTween.current.to(ref.current, {
          // scale: 2,
          scale: 3.5,
          opacityFactor: 0.5,
          // opacityFactor: 0.2,
          // timeFactor:0.3,
          x:0.9,
          y:0.2,
          // ease: "Power4.easeOut",
          clampMin:20.0,
          // duration: 0.5,
          // delay: 0,
          onUpdate:() => {
            // console.log("log update " + key, ref.current.scale)
          }
        }, "<")

        // myTween2.current.to(ref.current, {
        //   scale: 1.5,
        //   opacityFactor: 1.0,
        //   // timeFactor:0.1,
        //   x:positionBlob.x,
        //   y:positionBlob.y,
        //   // ease: "Power4.easeOut",
        //   // clampMin:2.0,
        //   // duration: 0.5,
        //   // delay: 0,
        //   onUpdate:() => {
        //     // console.log("log update " + key, ref.current.scale)
        //   }
        // }, "<")
      }
    // }
      
  }

  useEffect(()=>{
    // gsap.set("#background-wrapper canvas",{
    //   opacity:0
    // });
    if(opacityFactor > 0){
      gsap.to("#background-wrapper canvas",{
        opacity: 1,
        delay:0.8,
        onComplete:()=>{
          // ScrollTrigger.refresh()
          // ScrollTrigger.update()
        }
      })
    }else{
      gsap.to("#background-wrapper canvas",{
        opacity: 0,
        // delay:0.8,
        onComplete:()=>{
          // ScrollTrigger.refresh()
          // ScrollTrigger.update()

        }
      })
    }
    // ScrollTrigger.refresh()
    // ScrollTrigger.update()
    // alert("hello there")
  },[opacityFactor])

  const killGsapAnimation = () => {
    // console.log("kill animation")
    myTween.current.kill();
  }

  useEffect(() =>  {
    // console.log("gradient background render")
    // const vh = (coef) => window.innerHeight * (coef/100);
    // const vw = (coef) => window.innerWidth * (coef/100);
    // console.log("init gsap", ballMaterialRef2.current);
    gsap.registerPlugin(ScrollTrigger);

    if(myTween.current){
      // ScrollTrigger.refresh()
      // ScrollTrigger.update()
    }else{
      myTween.current = gsap.timeline({ 
        scrollTrigger: {
          id:"background_gradient_scrolltrigger",
          trigger: '#scroll-animation-helper',
          start: 'top top',
          // end: `${vh(100)} top`,
          end: `bottom top`,
          scrub: 3,
          // markers:true,
          // start: start,
          // end: '90%'
        }
      });
    }

    // if(myTween2.current){
    //   ScrollTrigger.refresh()
    //   ScrollTrigger.update()
    // }else{
    //   myTween2.current = gsap.timeline({ 
    //     scrollTrigger: {
    //       id:"background_gradient_scrolltrigger2",
    //       trigger: 'footer',
    //       start: 'top center',
    //       end: `top top`,
    //       scrub: 1,
    //       // markers:true,
    //       // start: start,
    //       // end: '90%'
    //     }
    //   });
    // }
    // if(firstRender.current){
    //   firstRender.current = false;
    // }else{
    //   myTween.current.to(gradientRefs.current[0].current, {
    //     scale: 500,
    //     ease: "Power3.easeInOut",
    //     // duration: 10,
    //     // delay: 0,
    //     onUpdate:() => {
    //       console.log("log update", gradientRefs.current[0].current.scale)
    //     }
    //   })
    // }
    // alert("hi there")
    return () => {
      myTween.current.kill();
      // myTween2.current.kill();
    }
  }, [])


  // useEffect(() => {
  //   ScrollTrigger.refresh();
  //   ScrollTrigger.update();
  //   console.log("refresh")
  // })

  // useEffect(() =>  {
  //   const vh = (coef) => window.innerHeight * (coef/100);
  //   const vw = (coef) => window.innerWidth * (coef/100);
  //   if(opacityFactor > 0){
  //     if(myTween.current){
  //       myTween.current.kill();
  //     }
  //     myTween.current = gsap.timeline({ 
  //       scrollTrigger: {
  //         trigger: '#__next',
  //         start: 'top',
  //         end: `${vh(150)} top`,
  //         scrub: 1,
  //         // markers:true,
  //         // start: start,
  //         // end: '90%'
  //       }
  //     });
  //   }

  //   return () => {
  //     myTween.current.kill();
  //   }
  // }, [opacityFactor])


  return (
    <Canvas
        camera={{ fov: 75, near: 0.1, far: 130, position: [0, 0, 10] }}
        gl={{ powerPreference: "high-performance", alpha: true, antialias: false, stencil: false, depth: false  }}
        // flat
        linear
        // frameloop='demand'
        dpr={0.1}
        performance={{
          current: 0.05,
          min: 0.01,
          max: 0.05,
          debounce: 200
        }}
        resize={{ scroll: false }}
        >
          <Suspense fallback={null}>
            <ShaderPlane positionMesh={[0,0,0]} colorStart={colorStart} colorEnd={colorEnd} positionBlob={positionBlob} scale={scale} opacity={1.0} timeFactor={0.15} keyBlob={1} initGsapAnimation={initGsapAnimation} killGsapAnimation={killGsapAnimation} />
          </Suspense>
    </Canvas>
  )
})

GradientBackground.displayName = "GradientBackground";
export default GradientBackground;
