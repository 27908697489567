import React, { useEffect, useContext } from "react"
// import PropTypes from "prop-types";
// import {
//   mediaPropTypes,
//   linkPropTypes,
//   buttonLinkPropTypes,
// } from "@/utils/types";
// import { useLockBodyScroll } from "@/utils/hooks";
import gsap from "gsap"
import { TransitionElementContext } from "../context/transitionElementContext";
import SplitText from "./elements/split-text";
import { isSafariBrowser, isEmbeddedBrowser } from "../utils/isBrowser";
// import { IFrame } from "../utils/IFrame";

const ease = {
  exponentialIn: (t) => {
    return t === 0.0 ? t : Math.pow(2.0, 10.0 * (t - 1.0));
  },
  exponentialOut: (t) => {
    return t === 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t);
  },
  exponentialInOut: (t) => {
    return t === 0.0 || t === 1.0
      ? t
      : t < 0.5
        ? +0.5 * Math.pow(2.0, (20.0 * t) - 10.0)
        : -0.5 * Math.pow(2.0, 10.0 - (t * 20.0)) + 1.0;
  },
  sineOut: (t) => {
    const HALF_PI = 1.5707963267948966;
    return Math.sin(t * HALF_PI);
  },
  circularInOut: (t) => {
    return t < 0.5
        ? 0.5 * (1.0 - Math.sqrt(1.0 - 4.0 * t * t))
        : 0.5 * (Math.sqrt((3.0 - 2.0 * t) * (2.0 * t - 1.0)) + 1.0);
  },
  cubicIn: (t) => {
    return t * t * t;
  },
  cubicOut: (t) => {
    const f = t - 1.0;
    return f * f * f + 1.0;
  },
  cubicInOut: (t) => {
    return t < 0.5
      ? 4.0 * t * t * t
      : 0.5 * Math.pow(2.0 * t - 2.0, 3.0) + 1.0;
  },
  quadraticOut: (t) => {
    return -t * (t - 2.0);
  },
  quarticOut: (t) => {
    return Math.pow(t - 1.0, 3.0) * (1.0 - t) + 1.0;
  },
}

const TransitionElement = () => {
  // Prevent window scroll while mobile nav menu is open
  const { transitionWord, transitionWordFunny, animateOut, fromHeaderMenu } = useContext(TransitionElementContext);
  //   const [backgroundClass, setBackgroundClass] = useState("background-grey");
  const isInitialMount = React.useRef(true);
  //   useLockBodyScroll();
  const timeline = React.useRef();
  const transitionRef = React.useRef();
  const timelineSafari = React.useRef();
  const textWrapperRef = React.useRef();

  const leftRef = React.useRef();
  const rightRef = React.useRef();
  const lineRef = React.useRef();

//   const backgrounds = [
//     "background-green",
//     "background-green-light",
//     "background-yellow",
//     "background-blue",
//   ];
  const splitTextHalf = (text) => {
    const wordCount = text.split(" ").length;
    let middle = Math.floor(text.length / 2);
    let before = text.lastIndexOf(" ", middle);
    let after = text.indexOf(" ", middle)

    if(wordCount > 1){
      if (middle - before < after - middle) {
          middle = before;
      } else {
          middle = after;
      }
    }

    var textLeft = text.substr(0, middle);
    var textRight = text.substr(middle);

    return {
      transitionWordLeft: textLeft,
      transitionWordRight: textRight
    };
  }

  const {transitionWordLeft, transitionWordRight} = splitTextHalf(transitionWord)



  useEffect(()=>{
    // console.log("Transition ELement created")
    if(!isSafariBrowser() && !isEmbeddedBrowser()){
      timelineSafari.current = new gsap.timeline();
    }

    timeline.current = new gsap.timeline();
    // gsap.set(transitionRef.current, {
    //   clipPath: "polygon(0 0, 100% 0, 100% 0%, 0 0%)"
    // })

    // enter
    timeline.current.set(textWrapperRef.current.querySelectorAll(".transition-word-left .split-text-letter"), {y:"100%", opacity:1})
    timeline.current.set(textWrapperRef.current.querySelectorAll(".transition-word-right .split-text-letter"), {y:"-100%", opacity:1})
    timeline.current.set(textWrapperRef.current.querySelectorAll(".transition-word-left"), {opacity:1})
    timeline.current.set(textWrapperRef.current.querySelectorAll(".transition-word-right"), {opacity:1})

    timeline.current.to(lineRef.current ,{
        height: "40%",
        duration: 0.7,
        ease:"Power3.easeOut",
    }, "<")
    var lettersLeft = Array.prototype.slice.call(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-left .split-text-letter"));
    var lettersRight = Array.prototype.slice.call(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-right .split-text-letter"));
    timeline.current.to(lettersLeft, {y:0, stagger:0.04, duration: 0.6, ease:"Power4.easeOut"})
    timeline.current.to(lettersRight, {y:0, stagger:0.04, duration: 0.6, ease:"Power4.easeOut"}, "<")

    timeline.current.to(lettersLeft.slice(1), {width:0, stagger:0.1, duration: 0.8, ease:"Power3.easeOut"})
    timeline.current.to(lettersLeft.slice(1), {opacity:0, stagger:0.1, duration: 0.4, ease:"Power3.easeOut"},"<")
    timeline.current.to(lettersLeft.slice(1), {y:"-100%", stagger:0.04, duration: 0.6, ease:"Power3.easeOut"}, "<")

    timeline.current.to(lettersRight.slice(2).reverse(), {width:0, stagger:0.1, duration: 0.8, ease:"Power3.easeOut"}, "<")
    timeline.current.to(lettersRight.slice(2).reverse(), {opacity:0, stagger:0.1, duration: 0.4, ease:"Power3.easeOut"}, "<")
    timeline.current.to(lettersRight.slice(2).reverse(), {y:"100%", stagger:0.04, duration: 0.6, ease:"Power3.easeOut"}, "<")

    // exit
    // timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-left .split-text-letter"), {y:"-100%", stagger:0, duration: 0.5, ease:"Expo.easeIn"})
    // timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-right .split-text-letter"), {y:"100%", stagger:0, duration: 0.5, ease:"Expo.easeIn"}, "<")
    // timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-right .split-text-letter"), {y:"100%", stagger:0, duration: 0.1, ease:"Expo.easeIn"})

    // word 2

    // timeline.current.set(textWrapperRef.current.querySelectorAll(".transition-word-left .split-text-letter"), {y:"100%", opacity:1})
    // timeline.current.set(textWrapperRef.current.querySelectorAll(".transition-word-right .split-text-letter"), {y:"-100%", opacity:1})

    // timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-left .split-text-letter"), {y:0, stagger:0.04, duration: 0.6, ease:"Power4.easeOut"})
    // timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-right .split-text-letter"), {y:0, stagger:0.04, duration: 0.6, ease:"Power4.easeOut"}, "<")

    // // exit
    // timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-left .split-text-letter"), {y:"-100%", stagger:0, duration: 0.5, ease:"Expo.easeIn"})
    // timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-right .split-text-letter"), {y:"100%", stagger:0, duration: 0.5, ease:"Expo.easeIn"}, "<")
    // timeline.current.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-right .split-text-letter"), {y:"100%", stagger:0, duration: 0.1, ease:"Expo.easeIn"})

    // timeline.current.set(textWrapperRef.current.querySelectorAll(".transition-word-left"), {opacity:0})
    // timeline.current.set(textWrapperRef.current.querySelectorAll(".transition-word-right"), {opacity:0})

    // timeline.current.to(lineRef.current ,{
    //     height: "100%",
    //     duration: 0.7,
    //     // delay:2.0,
    //     ease:"Power3.easeOut",
    // },"<")

    return ()=>{
      // console.log("Transition ELement killed")
      timeline.current.kill();
    }

  },[])

  useEffect(()=>{
    // const t1 = gsap.timeline();
    // const start =  "M 0 0   V 0   Q 50  0   100 0   V 0 z";
    // const middle = "M 0 0   V 50  Q 50  100 100 50  V 0 z";
    // const end =    "M 0 0   V 100 Q 100 100 100 100 V 0 z";
    // console.log("fromHeaderMenu", fromHeaderMenu, "animateOut", animateOut);
    // console.log("animateOut changed")
    if(animateOut){

      gsap.fromTo(leftRef.current ,{
        y:"0"
      },{
          y: "-110lvh",
          duration: 1.5,
          // delay:1.5,
          ease:"Power3.easeOut",
      })

      gsap.fromTo(rightRef.current ,{
        y:"0"
      },{
          y: "110lvh",
          duration: 1.5,
          ease:"Power3.easeOut",
      }, "<")



      gsap.fromTo(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-left .split-text-letter"),  
        {
          y:0
        },
        {
          y:"-100%", 
          duration: 0.6,
          ease:"Expo.easeIn"
        }, "<")

      gsap.fromTo(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-right .split-text-letter"), 
        {
          y:0
        },
        {
          y:"100%",
          duration: 0.6,
          ease:"Expo.easeIn"
        }, "<")

      gsap.fromTo(lineRef.current ,{
          height:"40%"
        },{
            height: "100%",
            // delay : -0.6,
            duration: 1.4,
            ease:"Power2.easeOut",
        }, "<")

      gsap.set(lineRef.current, {
        bottom:0,
        top:"unset"
      })


      gsap.set(textWrapperRef.current.querySelectorAll(".transition-word-left"), {delay:0.6, opacity:0})
      gsap.set(textWrapperRef.current.querySelectorAll(".transition-word-right"), {delay:0.6, opacity:0})

      gsap.set(lineRef.current, {
        top:0,
        delay: 0.5,
        bottom:"unset",
      })

      gsap.fromTo(lineRef.current ,{
        height:"100%"
      },{
          height: "0",
          delay : 0.5,
          duration: 1.5,
          ease:"Power3.easeOut",
      })

      gsap.set(transitionRef.current, {
        pointerEvents:"none",
        delay : 1.5,
        // clipPath: "polygon(0 0, 100% 0, 100% 0%, 0 0%)"
      })

    }else{
      if(isInitialMount.current){
          gsap.set(transitionRef.current, {
            autoAlpha:"1",
            // clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
              // duration: 0.8
          })
          isInitialMount.current = false;
      }else{
        gsap.set(transitionRef.current, {
          pointerEvents:"none",
          // clipPath: "polygon(0 0, 100% 0, 100% 0%, 0 0%)"
        })

        gsap.set(textWrapperRef.current.querySelectorAll(".transition-word-left"), {opacity:1})
        gsap.set(textWrapperRef.current.querySelectorAll(".transition-word-right"), {opacity:1})
        gsap.set(textWrapperRef.current.querySelectorAll(".transition-word-left .split-text-letter"), {y:"100%", opacity:1})
        gsap.set(textWrapperRef.current.querySelectorAll(".transition-word-right .split-text-letter"), {y:"-100%", opacity:1})

        gsap.set(lineRef.current, {
          bottom:0,
          top:"unset"
        })

        gsap.fromTo(lineRef.current ,{
          height:"0"
        },{
            height: "40%",
            duration: 0.8,
            ease:"Power3.easeOut",
        }, "<")

        gsap.fromTo(leftRef.current ,{
          y:"100%"
        },{
            y: 0,
            duration: 0.8,
            // delay:0.0,
            ease:"Power3.easeOut",
        },"<")

        gsap.fromTo(rightRef.current ,{
          y:"-100%"
        },{
            y: 0,
            duration: 0.8,
            ease:"Power3.easeOut",
        }, "<")


        gsap.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-left .split-text-letter"), {y:0, delay: 0.2, stagger:0.04, duration: 1, ease:"Power4.easeOut"}, "<")
        gsap.to(textWrapperRef.current.querySelectorAll(".transition-word.transition-word-right .split-text-letter"), {y:0, stagger:0.04, duration: 1, ease:"Power4.easeOut"}, "<")
        // gsap.to(transitionRef.current.querySelector(".noise"), {opacity:0, duration: 1, ease:"Power4.easeOut"}, "<")

        
      }
    }
  }, [animateOut])

  return (
    <>
    <div id="transition-element" className="flex flex-col justify-between" style={{position:"fixed", bottom:"0", left:"0", zIndex:"9999", width:"100%", height:"100%"}} ref={transitionRef}>

      {/* <div className="noise" style={{backgroundImage: `url("/noise-web.webp")`, pointerEvents:"none"}}></div> */}
      <div className="grid h-full grid-cols-2">
        <div id="transition-left" className="relative h-full p-0 m-0" style={{width:"100%"}}>
          <div className="w-full h-full" style={{position:"relative"}}>
            <div ref={leftRef} className="w-full h-full background-color" style={{position:"absolute", top:"0", left:"0"}}>
              <div className="noise" style={{backgroundImage: `url("/noise-web.webp")`, pointerEvents:"none"}}></div>
            </div>
          </div>
        </div>
        <div id="transition-right" className="h-full p-0 m-0" style={{width:"100%"}}>
            <div className="w-full h-full" style={{position:"relative"}}>
              <div ref={rightRef} className="w-full h-full background-color" style={{position:"absolute", top:"0", left:"0"}}>
                <div className="noise" style={{backgroundImage: `url("/noise-web.webp")`, pointerEvents:"none"}}></div>
              </div>
              <div ref={lineRef} className="" style={{position:"absolute", bottom:"0", left:"-2px", backgroundColor:"#91919188", width:"2px", height:"0"}}>
              </div>
            </div>
        </div>
      </div>
      <div className="w-full h-full word-container" style={{position:"absolute", top:"0", left:"0"}}>
        <div className="grid h-full grid-cols-2" ref={textWrapperRef}>
          <div className="flex items-center justify-end text-right">
            <div className="text-left" style={{paddingRight:"0.3ch"}}>
                <span className="transition-word transition-word-left" style={{opacity:"0"}}><SplitText text={transitionWordLeft} role=""/></span>
                {/* <span className="transition-word"><SplitText text={transitionWordLeft} role=""/></span> <span style={{fontSize:"clamp(14px, 1vw, 20px)", lineHeight:"1.2", display:"block"}} className="funny-word"><SplitText text={transitionWordFunny} role=""/></span> */}
            </div>
          </div>
          <div className="flex items-center justify-start">
            <div className="text-right" style={{marginLeft:"-0.3ch"}}>
                <span className="transition-word transition-word-right" style={{opacity:"0"}}><SplitText text={transitionWordRight} role=""/></span>
                {/* <span className="transition-word"><SplitText text={transitionWordRight} role=""/></span> <span style={{fontSize:"clamp(14px, 1vw, 20px)", lineHeight:"1.2", display:"block"}} className="funny-word"><SplitText text={transitionWordFunny} role=""/></span> */}
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <div style={{position:"fixed", top:"0", zIndex:"26", background:"white", height:"100vh", width:"100vw", pointerEvents:"none"}} ref={transitionRef}></div> */}
    </>
  );
};

export default TransitionElement;
