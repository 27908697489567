import React from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";


const Divider = ({color}) => {
  const timeline = React.useRef();
  const ref = React.useRef();
  React.useEffect(()=>{
    timeline.current = gsap.timeline({
      scrollTrigger: {
        id:"background_gradient_scrolltrigger",
        trigger: ref.current,
        start: 'top bottom',
        // end: `${vh(150)} top`,
        // scrub: 1,
        // markers:true,
        // start: start,
        // end: '90%'
      }
    })

    timeline.current.fromTo(ref.current, {
      width:"0",
    },{
      width:"100%",
      duration: 2.5,
      ease:"Power3.easeOut"
    })

    return ()=>{
      timeline.current.kill();
    }
  },[])
  return (
    <div className={`m-0 p-0 divider ${color}`} ref={ref}></div>
  )
}

export default Divider