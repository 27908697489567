import { useRef, Suspense, memo } from 'react'
import { Canvas } from '@react-three/fiber'
// import { Sphere, OrthographicCamera, PresentationControls, MeshWobbleMaterial, MeshDistortMaterial, MeshReflectorMaterial, Sparkles, Stars, Stage, Bounds, OrbitControls, AdaptiveDpr } from "@react-three/drei";
// import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'
import { Vector2, Color } from 'three';

// import { useSpring, animated } from '@react-spring/three'

import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import ShaderBall from './ShaderBall';
// import ShaderPlane from './ShaderPlane';

// import * as THREE from 'three'
import { getGradientColors } from '../../utils/colors';


const GradientBalls = memo(( {colorsStart, colorsEnd, positionsBlob, scale, circleScale, timeline, disableScrollAnimation=false, runAnimationInstead, animColorStart, animColorEnd} ) => {

  // const ballProps = useState(() => ({x: 0, y: 0, z: -50, scale: 50}));
  // const ballMaterialRef = useRef();
  // const ballMaterialRef2 = useRef();
  // const ballMaterialRef3 = useRef();
  // const ballMaterialRef4 = useRef();
  // const ballMaterialRef5 = useRef();
  // const myTween = useRef();
  // const firstRender = useRef(true);

  const initGsapAnimation = (meshRef, ref, animationColorStart, animationColorEnd) => {
      // console.log("init gsap", ref.current);

      // console.log(colorsStart);
      // console.log(colorsEnd);

      if(disableScrollAnimation){
        gsap.to(ref.current,
        {
            scale: 1.25,
            x:0.5,
            y:0.5,
            clampMin: 0.0,
            opacityFactor: 1.0,
        })
        if(runAnimationInstead){
          runAnimationInstead(ref, animationColorStart, animationColorEnd);
        }
        return;
      }
      gsap.set(ref.current, 
        // {
        //   scale: scale,
        // },
        {
        scale: 1.25,
        x:0.5,
        y:0.5,
        clampMin: 0.0,
        opacityFactor: 1.0,
        // clampMin:1.0,
        // positionBlob: new Vector2(0.5,0.5),
        // ease: "Power2.easeOut",
        // duration: 5,
        delay: 0,
        onUpdate:()=>{
          // console.log("log update", ref.current.scale,ref.current.x, ref.current.y)
        }
      })

      let colors = getGradientColors().base;
      let colorStart = new Color(colors.color1).convertLinearToSRGB()
      let colorEnd = new Color(colors.color2).convertLinearToSRGB()
      gsap.set(ref.current.colorStart,
      {
          r: colorStart.r,
          g: colorStart.g,
          b: colorStart.b,
          delay:0.2,
          duration: 0.8,
          ease: "Power3.easeInOut",
      })

      gsap.set(ref.current.colorEnd,
        {
            r: colorEnd.r,
            g: colorEnd.g,
            b: colorEnd.b,
            delay:0.2,
            duration: 0.8,
            ease: "Power3.easeInOut",
        })

      // gsap.set(ref.current.colorEnd,
      // {
      //     r: newEndColor.r,
      //     g: newEndColor.g,
      //     b: newEndColor.b,
      //     delay:0.2,
      //     duration: 0.8,
      //     ease: "Power3.easeInOut",
      // }, `field-element-${index}`)

      

      colorsStart.forEach((color, index)=>{
          let newStartColor = new Color(color)
          let newEndColor = new Color(colorsEnd[index])

          timeline.current.to(ref.current,
          {
              scale: 5,
              x:0.5,
              y:0.5,
              clampMin: 0.0,
              opacityFactor: 1.0,
              duration: 0.5,
              ease: "Power3.easeIn",
          }, `field-element-${index}`)
          timeline.current.to(ref.current,
          {
              // scale: 1.5,
              scale: 1.25,
              x:0.5,
              y:0.5,
              clampMin: 0.0,
              opacityFactor: 1.0,
              duration: 0.5,
              delay:0.5,
              ease: "Power3.easeOut",
          }, `field-element-${index}`)

          timeline.current.addLabel("third").to(meshRef.current.rotation, {
              // z: 6.28,
              // z: 1 * Math.PI * (index + 1) ,
              z: 2*Math.PI * (index + 1) ,
              // x: 1 * Math.PI * (index + 1),
              // y: -1 * Math.PI * (index + 1),
              // x: 6.28,
              ease: "Power3.easeInOut",
              duration: 1.0,
          }, `field-element-${index}`)
      
          timeline.current.to(ref.current.colorStart,
          {
              r: newStartColor.r,
              g: newStartColor.g,
              b: newStartColor.b,
              delay:0.2,
              duration: 0.8,
              ease: "Power3.easeInOut",
          }, `field-element-${index}`)

          timeline.current.to(ref.current.colorEnd,
          {
              r: newEndColor.r,
              g: newEndColor.g,
              b: newEndColor.b,
              delay:0.2,
              duration: 0.8,
              ease: "Power3.easeInOut",
          }, `field-element-${index}`)
      })
  }

  // useEffect(() =>  {
  //   // console.log("init gsap", ballMaterialRef2.current);
  //   gsap.registerPlugin(ScrollTrigger);

  //   myTween.current = gsap.timeline({ 
  //     scrollTrigger: {
  //       trigger: '.hero',
  //       start: 'top',
  //       end: `150%`,
  //       scrub: 1,
  //       // markers:true,
  //       // start: start,
  //       // end: '90%'
  //     }
  //   });
  //   // if(firstRender.current){
  //   //   firstRender.current = false;
  //   // }else{
  //   //   myTween.current.to(gradientRefs.current[0].current, {
  //   //     scale: 500,
  //   //     ease: "Power3.easeInOut",
  //   //     // duration: 10,
  //   //     // delay: 0,
  //   //     onUpdate:() => {
  //   //       console.log("log update", gradientRefs.current[0].current.scale)
  //   //     }
  //   //   })
  //   // }

  //   return () => {
  //     myTween.current.kill();
  //   }
  // }, [])

  return (
    <Canvas dpr={[1,2]}
        camera={{ fov: 30, near: 0.1, far: 100, position: [0, 0, 10] }}
        gl={{ powerPreference: "high-performance", alpha: true, antialias: false, stencil: false, depth: false  }}
        // flat
        linear
        // concurrent
        performance={{
          current: 0.05,
          min: 0.01,
          max: 0.05,
          debounce: 200
        }}
        >

          <Suspense fallback={null}>
            <ShaderBall timeline={timeline} positionMesh={[0,0, 0]} colorsStart={colorsStart} colorsEnd={colorsEnd} positionBlob={new Vector2(0.5,0.5)} positionBlobEnd={new Vector2(1.0,0.5)} opacity={0.0} scale={1.5} initGsapAnimation={initGsapAnimation} animColorStart={animColorStart} animColorEnd={animColorEnd} />
          </Suspense>
    </Canvas>
  )
})

GradientBalls.displayName = "GradientBalls"

export default GradientBalls