import React, { createContext, useState } from "react";
// import gsap from "gsap";

export const TransitionElementContext = createContext({
  transitionWord: "",
  transitionWordFunny: "",
  animateOut: false,
  animateChildren: false,
  fromHeaderMenu: false,
  pageTransitionElementHandler: () => {},
  openTransitionElementHandler: ( )=> {},
  closeTransitionElementHandler: ( )=> {},
  preventAnimateChildren:()=>{},
  allowAnimateChildren:()=>{},
});

const TransitionElementContextProvider = (props) => {
  const [transitionWord, setTransitionWord] = useState("couto.mixto");
  const [transitionWordFunny, setTransitionWordFunny] = useState("of risk-taking");
  const [animateOut, setAnimateOut] = useState(false);
  const [fromHeaderMenu, setFromHeaderMenu] = useState(false);
  const [animateChildren, setAnimateChildren] = useState(false);

  const pageTransitionElementHandler = (url, transitionWord = "We've never learned much", transitionWordFunny = "from playing it safe") => {
    // console.log(url, props.location)
    // setTransitionWord(transitionWord ?? "Antecâmara Studio");
    setTransitionWord("c.m");
    setTransitionWordFunny(transitionWordFunny);
    // console.log("pageTransitionElementHandler")
    setAnimateOut(false);
    // if(url != props.location){
    //   setAnimateOut(false);
    // }
  };

  const openTransitionElementHandler = () => {
    // console.log(fromHeaderMenu);
    // console.log("open Handler")
    setAnimateOut(false);
  };

  const closeTransitionElementHandler = () => {
    // console.log("close Handler")
    setAnimateOut(true);
  };


  const preventAnimateChildren = () => {
    // console.log(fromHeaderMenu);
    // console.log("open children")
    setAnimateChildren(false);
  };

  const allowAnimateChildren = () => {
    // console.log("close Handler")
    setAnimateChildren(true);
  };



  const isFromHeaderMenu = (fromHeader = false) => {
    if(fromHeader != fromHeaderMenu){
      setFromHeaderMenu(fromHeader);
    }
  }

  return (
    <TransitionElementContext.Provider
      value={{
        transitionWord: transitionWord,
        transitionWordFunny: transitionWordFunny,
        animateOut: animateOut,
        animateChildren: animateChildren,
        fromHeaderMenu: fromHeaderMenu,
        pageTransitionElementHandler: pageTransitionElementHandler,
        openTransitionElementHandler: openTransitionElementHandler,
        isFromHeaderMenu: isFromHeaderMenu,
        closeTransitionElementHandler: closeTransitionElementHandler,
        allowAnimateChildren: allowAnimateChildren,
        preventAnimateChildren: preventAnimateChildren,
      }}
    >
      {props.children}
    </TransitionElementContext.Provider>
  );
};

export default TransitionElementContextProvider;