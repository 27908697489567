import { useEffect, useRef, useState, forwardRef, useLayoutEffect, createRef, Suspense } from 'react'
import { Canvas, useFrame, useThree, useControls } from '@react-three/fiber'
import { BlobBallMaterial } from './BlobMaterialBall'
import * as THREE from 'three'
// import { Sphere, OrthographicCamera, PresentationControls, MeshWobbleMaterial, MeshDistortMaterial, MeshReflectorMaterial, Sparkles, Stars, Stage, Bounds, OrbitControls, AdaptiveDpr } from "@react-three/drei";
// import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'
import { Vector2, Vector3 } from 'three';

// import { useSpring, animated } from '@react-spring/three'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { duration } from 'moment';



const ShaderBall = ( { colorsStart, colorsEnd, positionBlob, positionBlobEnd, scale, opacity, positionMesh, invert = false, timeline, initGsapAnimation, animColorStart, animColorEnd } ) => {
    const ref = useRef()
    const meshRef = useRef()
    const { width, height } = useThree((state) => state.viewport)
    const { gl, viewport, size } = useThree()
    const myTween = useRef();
    const {x, y} = {x: positionBlob.x, y: positionBlob.y};
    const meshPosition = useRef({x: positionMesh.x, y: positionMesh.y, z: positionMesh.z});


    const phiStart = 0;
    const phiEnd = Math.PI * 2;
    const thetaStart = 0;
    const thetaEnd = Math.PI / 2;



    // const { position } = useSpring({
    //   to: {
    //     position: [width/2, height/2, 0],
    //   }, 
    //   from: { position: [0,0,0] },
    //   config: { mass: 5, tension: 500, friction: 150 }
    // })

    useEffect(() =>  {
      // console.log("init animation Shader")
      initGsapAnimation(meshRef, ref, animColorStart, animColorEnd);
      // gsap.registerPlugin(ScrollTrigger);

      // let newColor = new THREE.Color("#80B28D")

    //   myTween.current = gsap.timeline({
    //     // yes, we can add it to an entire timeline!
    //     scrollTrigger: {
    //       id:"fieldScrollTrigger",
    //       trigger: ".fields-section",
    //       pin: true,   // pin the trigger element while active
    //       start: "top top", // when the top of the trigger hits the top of the viewport
    //       end: "400%", // end after scrolling 500px beyond the start
    //       scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    //       pinSpacing:true,
    //       markers:true,
    //       snap: {
    //         snapTo: "labels", // snap to the closest label in the timeline
    //         duration: {min: 0.2, max: 0.7}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
    //         delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
    //         ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
    //       },
    //       onSnapComplete: () => console.log(timeline.current.currentLabel())
    //     }
    //   });
      // gsap.core.globals("ScrollTrigger", ScrollTrigger);
      // myTween.current.to(ballMaterialRef.current, {
      //   scale: 10, 
      //   ease: "Power3.easeInOut",
      //   duration: 0.1,
        
      // })
      // gsap.set(ref.current, 
      //   // {
      //   //   scale: scale,
      //   // },
      //   {
      //   scale: 50,
      //   opacityFactor: 0.0,
      //   x:0.5,
      //   y:0.5,
      //   // clampMin:1.0,
      //   // positionBlob: new Vector2(0.5,0.5),
      //   // ease: "Power2.easeOut",
      //   // duration: 5,
      //   delay: 0,
      //   onUpdate:()=>{
      //     // console.log("log update", ref.current.scale,ref.current.x, ref.current.y)
      //   }
      // })

      // colorsStart.forEach((color, index)=>{
      //     let newStartColor = new THREE.Color(color)
      //     let newEndColor = new THREE.Color(colorsEnd[index])

      //     timeline.current.to(ref.current,
      //     {
      //         scale: 2,
      //         x:positionBlob.x,
      //         y:positionBlob.y,
      //         clampMin: 0.0,
      //         opacityFactor: 0.8,
      //     }, `field-element-${index}`)

      //     timeline.current.addLabel("third").to(meshRef.current.rotation, {
      //         // z: 6.28,
      //         z: Math.PI * (index + 1),
      //         // x: 6.28,
      //         ease: "Power1.easeInOut",
      //         duration: 1.5,
      //     }, `field-element-${index}`)
      
      //     timeline.current.to(ref.current.colorStart,
      //     {
      //         r: newStartColor.r,
      //         g: newStartColor.g,
      //         b: newStartColor.b,
      //     }, `field-element-${index}`)

      //     timeline.current.to(ref.current.colorEnd,
      //     {
      //         r: newEndColor.r,
      //         g: newEndColor.g,
      //         b: newEndColor.b,
      //     }, `field-element-${index}`)
      // })

      // // timeline.current.to(meshRef.current.position, {
      // //   x:0,
      // //   y:0,
      // //   z:0,
      // //   // positionBlob: new Vector2(0.5,0.5),
      // //   // ease: "Power2.easeOut",
      // //   // duration: 5,
      // //   delay: 0,
      // //   onUpdate:()=>{
      // //     // console.log("log update",  meshPosition.current.x, meshPosition.current.y, meshPosition.current.z)
      // //   }
      // // })
    }, [initGsapAnimation])

    
    useFrame((state, delta) => {
      ref.current.time += delta;
      // meshRef.current.rotation.z += delta
      // materialRef.current.scale = scale;
    })
    // console.log(viewport)
    return (
      <mesh ref={meshRef} position={new Vector3(meshPosition.current.x, meshPosition.current.y, meshPosition.current.z)} scale={[1, 1, 1]}>
        {/* <planeGeometry position={position} args={[1, 1, 16, 16]} /> */}
        <circleBufferGeometry args={[ Math.min(viewport.width/2, viewport.height/2), 258]} />
        {/* <sphereBufferGeometry args={[ Math.min(viewport.width/2, viewport.height/2 - 1), 512, 256]} /> */}
        {/* We use the materials module 🔑 to allow HMR replace */}
        <blobBallMaterial ref={ref} colorStart={colorsStart[0]} colorEnd={colorsEnd[0]} x={x} y={y} scale={3} opacityFactor={1.0} timeFactor={0.5} transparent clampMin={1.0} />
      </mesh>
    )
}



export default ShaderBall